// Here you can add other styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  letter-spacing: 0.3px;
  background-color: #f8f8f8;
}

img {
  font-size: 12px;
}

textarea {
  padding: 8px;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  box-shadow: none !important;
}

.MuiFormLabel-root {
  color: #828282 !important;
  letter-spacing: 0.5px !important;
}

.MuiInputBase-input {
  color: #333 !important;
  font-size: 15px !important;
  letter-spacing: 0.3px !important;
}

.makeStyles-content-3 {
  padding: 10px 5px !important;
  overflow-x: hidden;
}

.validation-error {
  color: #f16667;
}

.fixed-plugin {
  display: none;
}

.makeStyles-sidebarMinimize-88 {
  padding-left: 12px !important;
}

.makeStyles-button-91 svg {
  width: 20px !important;
  height: 20px !important;
}

footer {
  padding: 0 !important;

  .MuiListItem-root,
  ul li a {
    font-size: 14px;
    color: #999 !important;
    font-weight: 400;
  }

  .footer-right {
    padding: 15px 0;
    font-size: 14px;
    color: #999;
    font-weight: 400;
  }
}

.cursorPointer {
  cursor: pointer;
}

// ====== BookShellCard =======
.bookShellCard {
  width: 100% !important;
  height: 100%;
  margin: 0 !important;
  overflow: hidden;
}

.viewDetailHover {
  cursor: pointer;
  transition: all 0.1s linear;
  &:hover {
    transform: translateY(-5px);
    transition: all 0.2s linear;
  }
}

.my-book-shelf {
  margin-top: 25px !important;

  .my-book-shelf-grid-item {
    margin-bottom: 15px !important;
  }
}

.bookShellCard {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 10px !important;

  .bookShellCard-grid-item {
    padding: 0 !important;

    .w-100 {
      padding: 2px 8px;
      vertical-align: top;
    }

    .books-top-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      margin-right: -10px;

      * {
        cursor: pointer;
      }

      .fa-pencil {
        margin: 0 5px 0 18px;
        color: $primary-color !important;
      }

      .MuiSwitch-root {
        transform: scale(0.7);
        .Mui-checked ~ .MuiSwitch-track {
          background-color: #28a745 !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

// ========== View Details ==========

.view-details-page {
  .bookShellModal {
    overflow-y: auto;
    height: auto !important;

    > div {
      height: auto !important;
    }

    .card-header-left {
      padding-right: 5px !important;
      .card-heading {
        text-transform: uppercase;
      }

      .view-category {
        margin-bottom: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .card-header-right {
      padding-left: 5px !important;
      h4,
      a {
        display: block;
        text-align: right;
        margin-top: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .card-body {
      > div {
        height: auto !important;
      }
    }
  }

  .bookShellCard-grid-item {
    .ViewDetialLeft {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    .ViewDetialsFullImg {
      width: 100%;
      height: 250px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.05);
      img {
        object-fit: contain;
      }
    }

    .ViewDetialImgList {
      width: 100%;
      margin-top: 5px;

      nav {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-bottom: 0 !important;

        .view-details-img-item {
          width: 50px;
          height: 50px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 0 !important;
          cursor: pointer;
          overflow: hidden;

          &:last-child {
            margin: 0 !important;
          }
        }
      }
    }

    .ViewDetialRight {
      width: 100% !important;
      padding: 10px;
      .card-button-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0 !important;
        position: static !important;
        button {
          padding: 0 15px !important;
        }
      }

      .price-location {
        display: flex;
        margin-top: 10px;

        .view-price {
          margin-right: 50px;
          margin-bottom: 5px;
          display: inline-block;
        }
      }

      .view-details-text {
        text-align: left;
      }

      .view-interested {
        margin-top: 20px;
        text-align: center;
      }

      .view-details-parahead {
        color: #4f4f4f;
        margin-top: 10px;
        font-weight: 500 !important;
        margin-bottom: 8px !important;
      }

      .view-details-para {
        color: #828282;
        font-weight: 400 !important;
      }
    }
  }
}

.bookShellCard form {
  margin-bottom: 20px;
}

.bookShellCard > div,
.makeStyles-grid-172 {
  width: 100% !important;
  margin: 0 !important;
  height: 100% !important;
  padding: 8px;
}

.bookShellCard .makeStyles-grid-173 {
  padding: 0 !important;
}

.bookShellCard hr {
  margin: 5px 0 !important;
}

// ======= BookShellModal ========
.MyBookShelfModal {
  .card-header {
    padding: 15px 15px 11px;
  }

  .card-heading {
    font-size: 16px;
  }

  .modalBody {
    overflow: hidden;
  }

  .w-100 {
    padding: 12px 15px;
    overflow-y: auto;
    max-height: calc(100vh - 219px) !important;
  }
}

.bookShellModal {
  .bookShellCard {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .formAuthorChips,
  .MuiFormGroup-root {
    margin-top: 15px;
  }

  .MuiFormGroup-root {
    justify-content: space-between;
  }

  .makeStyles-grid-173 {
    padding: 0 15px !important;
  }

  .card-footer {
    background-color: #fff !important;
    padding: 10px 15px !important;

    button:first-child {
      margin-right: 15px;
    }

    .closeBtn,
    .addBtnModal {
      height: 34px;
      padding: 0 16px !important;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      background-color: #fff;
      font-weight: 400 !important;
      font-size: 14px;
      color: #828282;
      text-transform: capitalize;
      box-shadow: none;
      margin: 0;
    }

    .addBtnModal {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;
    }
  }
}

.category-location {
  width: 100% !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;

  .bookShellCard-category,
  .bookShellCard-location {
    font-weight: 500;
    color: #4f4f4f;

    span {
      font-weight: 400;
    }
  }
}

// ============== Modal Custom Input =========
.uploadProfile {
  cursor: pointer;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 15px !important;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.uploadProfile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.uploadProfile i {
  position: absolute;
  bottom: 5px;
  right: -2px;
  font-size: 12px;
  color: #999;
  width: 26px;
  height: 26px;
  line-height: 24px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 50%;
}

.uploadProfileGrid {
  margin-top: 20px;
  padding-right: 15px !important;
  > div {
    padding-right: 0 !important;
  }
  .uploadProfile {
    margin: 0 !important;
    width: 100px !important;
    height: 100px !important;
  }
}

// =========== Faq Search Form ===========
.searchBarFaqWrap {
  margin: 15px 0 25px;
  position: relative;
}

.clearBtn {
  position: absolute !important;
  background: transparent !important;
  padding: 0 4px !important;
  top: 8px;
  left: 8px;
  box-shadow: none !important;
  z-index: 99;
  font-size: 18px !important;
  color: #bbb !important;
}

.searchBarFaq {
  border: 1px solid $primary-color;
  border-radius: 8px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  color: #828282;
  padding: 13px 42px 13px 38px;
  font-weight: 400;
  font-size: 14px;
}

.searchBtnFaq {
  position: absolute !important;
  margin: 0 !important;
  right: 8px;
  top: 7px;
  padding: 2px !important;
  background-color: $primary-color !important;
  color: #fff !important;
  border-color: $primary-color !important;
  border-radius: 6px !important;
  height: 30px;
  width: 30px;
  font-size: 14px !important;
  box-shadow: none !important;
}

.sectionTitle {
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.customAccordion {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:before {
    display: none;
  }

  .MuiAccordionSummary-root {
    padding: 0 12px;

    .MuiIconButton-root {
      padding: 4px 8px;
    }
  }

  .MuiTypography-body1 {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #333;
  }

  .MuiAccordionSummary-content {
    margin: 14px 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 14px 0;
  }

  .MuiCollapse-wrapper {
    .MuiAccordionDetails-root {
      flex-direction: column;
    }
  }

  .MuiAccordionDetails-root {
    padding: 2px 12px 15px;

    .MuiTypography-body1 {
      color: #4f4f4f;
    }
  }
}

.customList {
  padding-bottom: 0 !important;

  li {
    width: 100%;
    padding: 6px 0;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #4f4f4f;
    font-weight: 400;
    list-style-type: circle;
    list-style-position: inside;
    display: list-item;
  }
}

// ======== Notification Page ==========
.notificationsWrap {
  margin-top: 20px !important;
  padding: 0 !important;
  border-radius: 10px;

  li {
    flex-direction: column;
    align-items: flex-start !important;
    background-color: #fff;
    padding: 14px 13px;
    font-size: 14px;
    color: #4f4f4f;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    span {
      color: $primary-color;
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      margin-bottom: 3px;
    }

    a {
      display: block !important;
      color: #4f4f4f;
      text-decoration: none;
      width: 100%;
    }
  }
}

// =========== Contact Us Page ============

.contactForm label.MuiFormLabel-root {
  padding: 0 0 7px;
}

.contactForm .MuiFormControl-root {
  margin-bottom: 15px;
}

// ============ Login Page ============
.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(45deg, $primary-color 0%, #e6d1b9 100%);

  // Row
  .makeStyles-grid-1.h-100,
  .MuiGrid-root.h-100 {
    width: 100% !important;
    overflow-y: auto;
    margin: 0 !important;

    // Col
    > .makeStyles-grid-2 {
      padding: 0 !important;
      &:last-child {
        padding-left: 20px !important;
      }
    }

    .public-footer {
      margin: 0 !important;
      margin-top: 30px !important;
      width: 100% !important;
    }
  }

  .logoImg {
    margin: 0 0 30px;
  }

  h6 {
    text-transform: capitalize !important;
  }

  .loginForm a {
    text-decoration: none !important;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
  }

  .fa {
    color: #b5b5b5 !important;
    margin-bottom: 3px;
    font-size: 14px;
  }

  .fa.text-theme {
    color: $primary-color !important;
  }

  .margin-top {
    margin-top: 20px !important;
  }

  .fa-check-circle {
    color: #28a745 !important;
  }

  .fa-times-circle {
    color: #dc3545 !important;
  }
}

.loginPage > div {
  height: 94%;
  width: 90vw;
  overflow-y: auto !important;
}

.logoImg {
  width: 150px;
  margin: 30px 0;
}

.registerPage .logoImg {
  width: 100px !important;
}

.pl-0.makeStyles-grid-173 {
  padding-left: 0 !important;
}

.pr-0.makeStyles-grid-173 {
  padding-right: 0 !important;
}

.loginForm {
  width: 80%;
  margin: 20px auto 15px;
  max-width: 360px;

  .loginButton {
    align-items: center;
    margin-top: 20px !important;
    // a {
    //   min-width: 50%;
    //   button {
    //     width: 100%;
    //   }
    // }

    .MuiButton-root {
      width: 225px;
    }
  }
}

.formSubmit .MuiButton-label {
  font-size: 16px;
  text-transform: capitalize !important;
}

/* ============== Footer =========== */
.login-footer-links {
  font-size: 14px !important;
  color: #999;
  font-weight: 400;

  a {
    margin-left: 15px;
    font-size: 14px !important;
    color: #999 !important;
  }
}

.poweredBy {
  display: block;
  text-align: right;
}

.poweredBy a {
  margin-left: 5px;
  color: #31c5c7 !important;
}

// =================
.Sidebar-blue-50,
.Sidebar-blue-50:hover,
.Sidebar-blue-50:focus {
  box-shadow: 0 12px 20px -10px $primary-color !important;
  background-color: #ae8458 !important;
}

.swap {
  background-color: #5e80c1 !important;
}

.proud-owner {
  background-color: #d97343 !important;
}

.sale {
  background-color: #884241 !important;
}

.content .pageTitle {
  font-size: 25px !important;
  font-weight: 500 !important;
  margin: 5px 0;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.notif-power {
  display: flex;
  align-items: center;
}

// ============= My Requests ================
.my-requests-button {
  margin-top: 20px !important;
  button {
    padding: 11px 12px;
    width: 160px;
    background-color: white !important;
    color: $primary-color !important;
    transition: all 0.3s linear;
    font-size: 18px;
    letter-spacing: 0.5px;
    border-radius: 10px !important;

    &:hover {
      background-color: $primary-color !important;
      color: white !important;
    }
  }

  .activeBtn {
    background-color: $primary-color !important;
    color: white !important;
  }

  .ml {
    margin-left: 30px !important;
  }
}

// ======= Clear All ==========

.clear-all {
  cursor: pointer;
  font-size: 15px;
  color: #828282;
  font-weight: 500;

  i {
    margin-left: 8px;
  }
}

// ============ UserBookshelf =============
.userBookShelf {
  .clear-all {
    justify-content: flex-start;
  }
}

// ========== My Request Form ==========

.my-requests-form {
  margin-top: 25px !important;

  .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  .MuiSelect-iconOutlined {
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 100%;
  }

  .search-input {
    position: relative;
    margin-left: -15%;

    .input-icon {
      position: absolute;
      top: 17px;
      right: 7px;
    }
  }

  .MuiInputLabel-outlined {
    color: #c4c4c4;
  }

  .MuiOutlinedInput-root {
    width: 100% !important;
  }

  .MuiFormControl-root {
    margin-top: 0 !important;
  }

  .filter-text {
    margin-left: 20px !important;
  }
}

// ======= Book Table Card =========
.bookTableCard {
  margin-top: 30px !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 12px !important;
  margin-bottom: 15px !important;

  .MuiTableCell-root {
    font-size: 14px !important;
    text-transform: capitalize;
    border-bottom: none !important;
    color: #333;
    padding: 12px;

    .MuiOutlinedInput-input {
      padding: 13px 50px 11px 10px;
      font-size: 14px !important;
    }
  }

  .MuiTableHead-root .MuiTableCell-root {
    text-transform: uppercase;
  }

  .MuiTableCell-root.action-sent,
  .clear-all {
    color: $primary-color !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .MuiTableCell-root.action-received {
    span {
      cursor: pointer;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    .line {
      margin: 0 5px;
      cursor: default;
    }

    .btnAccept,
    .btnDelete {
      background-color: #fff;
      border: 0;
      color: #28a745;
      padding: 5px 0;
      text-transform: capitalize;
      font-size: 14px;
      letter-spacing: 0.3px;
      box-shadow: none;
      margin: 0;
      margin-right: 15px;

      span {
        font-weight: 400;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .btnDelete {
      color: #dc3545;
      margin-right: 0;
    }
  }

  .MuiTableHead-root {
    .MuiTableCell-head {
      color: $primary-color !important;
      font-size: 14px;
    }
    .MuiTableRow-root {
      border-bottom: 1px solid $primary-color !important;
    }
  }
}

// ========= My Profile Page ===========
.my-profile {
  margin-top: 25px !important;
  padding: 30px 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 12px !important;
  margin-bottom: 20px !important;

  .makeStyles-cardBody-180 {
    padding: 0;
  }

  .profile-imgbox {
    h1 {
      text-align: center;
      font-size: 18px;
    }
  }

  .my-profile-form {
    margin-top: 15px;
    margin-bottom: 0;

    .ModalFormRow {
      .flex-center {
        display: flex;
        justify-content: center;
        align-self: flex-start;
        margin-bottom: 10px;

        > .MuiFormControl-root {
          width: 90% !important;

          .MuiInputBase-root {
            width: 100% !important;
            align-self: flex-start;
          }
        }
      }

      .customTag {
        margin-top: 10px;

        .MuiInputLabel-root {
          font-size: 12px;
          display: block;
          width: 96% !important;
          margin: 0 auto !important;
        }

        > .MuiFormControl-root {
          width: 96% !important;
          display: block;
          margin: -8px auto 10px !important;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.23);
        }

        .MuiOutlinedInput-root {
          border-radius: 0;
        }
      }
    }

    .submitBtn {
      text-align: center;

      button {
        margin-top: 30px;
        padding: 12px 60px;
      }
    }
  }
}

// ======== Browse All Books ===========
.browse-all-books {
  .search-input {
    margin: 0 !important;
  }

  .featured-categories {
    margin: 35px 0 15px;
    .featured-categories-container {
      margin: 15px 0;
      > div {
        margin: 15px 0;
      }
      .featured-categories-box {
        position: relative;
        border: 1px solid $primary-color;
        height: 100% !important;
        .featured-categories-img {
          opacity: 0.1;
          transition: all 0.2s linear;
        }
        .featured-categories-link {
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          text-transform: uppercase;
          line-height: 1.3;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s linear;
        }

        &:hover {
          .featured-categories-img {
            opacity: 0.2;
          }

          .featured-categories-link {
            font-size: 22px !important;
          }
        }
      }
    }
  }
}

// =========== Chat Box =========
.chat-page {
  .clear-all {
    justify-content: flex-start !important;
  }
  .chatBoxModal {
    margin-top: 20px !important;
    margin-bottom: 0 !important;
    height: calc(100vh - 208px);
    padding: 0 !important;

    .chatBoxContainer {
      #chat-panel {
        padding-right: 5px;
        margin-top: 5px;
        height: calc(100vh - 330px);
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 0;
        scrollbar-color: #e0e0e0 #f5f5f5;
        scrollbar-width: thin;
      }

      #chat-panel::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.18);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        background-color: #f5f5f5;
      }

      #chat-panel::-webkit-scrollbar {
        width: 3px;
        background-color: #f5f5f5;
      }

      #chat-panel::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.18);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #e0e0e0;
      }

      // Chat User Profile
      .profile-imgbox {
        display: flex;
        align-items: center;
        .uploadProfile {
          width: 34px;
          height: 34px;
          margin: 0 !important;
        }

        h1 {
          margin: 0 12px !important;
        }
      }

      .chatBox {
        margin-bottom: 30px;
        .date-box {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 20px;
        }

        .chatInnerBox {
          margin-bottom: 12px;
          .img-box {
            margin-top: 15px;
            img {
              width: 70px !important;
              height: 70px !important;
              border-radius: 10px;
              overflow: hidden;
            }
          }

          .text-box {
            max-width: 95%;
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            p {
              padding: 10px;
              border-radius: 10px;
              background: #fbdebc;
              font-size: 14px;
              color: #333;
              margin-bottom: 3px;
              width: fit-content;
            }
            span {
              display: block;
              color: #4f4f4f;
              font-size: 13px;
            }
          }
        }

        // ======== Message Received =========
        .received {
          .text-box {
            p {
              border-bottom-left-radius: 0;
              align-self: flex-start;
            }
            span {
              text-align: left;
            }
          }
        }

        // ======== Message Sent =========
        .sent {
          .text-box {
            p {
              border-bottom-right-radius: 0;
              background: #fff3e7;
              align-self: flex-end;
            }
            span {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

// ======== Chat Messsage ========

.chatBoxMessage {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  padding: 0 7px;

  .chat-form {
    .my-requests-form {
      margin-top: 0 !important;
      .search-input {
        margin-left: 0 !important;

        i {
          font-size: 20px;
          right: 35px;
        }

        i.image {
          right: 80px;
        }
      }
    }

    .MuiInputBase-input {
      padding-left: 15px;
      padding-right: 35px;
    }
  }
}

.welcome-message {
  color: $primary-color !important;
  font-weight: 400;
  margin-left: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

// ========= Date Range =============

.DateRangePickerInput__withBorder {
  display: flex !important;
  align-items: center;
  border-color: black !important;
  border-radius: 4px !important;
  padding: 5px 0 !important;
  background-color: transparent !important;

  .DateRangePicker_picker {
    z-index: 99;
  }

  .DateInput {
    background: transparent !important;
    .DateInput_input {
      font-size: 15px !important;
      background: transparent !important;
      border: none !important;
      color: #333 !important;
    }
  }

  .DateRangePickerInput_arrow_svg {
    height: 12px !important;
    width: 15px !important;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #a8875f !important;
  border: 1px double #a8875f !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #caaa88 !important;
  border: 1px double #b99466 !important;
  color: #fff !important;
}

.register-form {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;

  .loginButton {
    margin-top: 30px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

// ==== Register Page =====
.registerPage {
  .loginForm {
    width: 85% !important;
    max-width: 450px;
  }
}

// filter css
.customFilterWrap {
  display: flex;

  .searchBook {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
  }

  label {
    font-size: 14px;
    color: $primary-color;
    font-weight: 400;
    margin-bottom: 3px;
    display: inline-block;
  }

  .search-input {
    margin-left: 0;
  }
}

.homePgFilter {
  label {
    font-size: 14px;
    color: $primary-color;
    font-weight: 400;
    margin-bottom: 3px;
    display: inline-block;
  }

  .MuiGrid-root {
    margin-bottom: 15px;
  }
}

.MuiPagination-ul {
  padding-top: 0 !important;
}

.MuiPaginationItem-sizeLarge {
  height: 26px !important;
  min-width: 26px !important;
  border-radius: 50% !important;
  padding: 0 2px !important;
  line-height: 26px !important;
  font-size: 14px !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.clear-all-custom {
  float: right;
  margin: 10px 5px 20px 0;
}

.bookTableCard + .MuiBox-root {
  margin-bottom: 30px;
}

.addBtn {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 34px;
  padding: 0 18px !important;
  letter-spacing: 0.3px !important;
  margin: 0 0 5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-0 {
  margin-top: 0;
}

.backBtn {
  font-size: 14px;
  color: #999;
  margin-right: 10px;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  border: 1px solid#999;
  border-radius: 50%;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
  cursor: pointer;
  padding-top: 1px;
}

header + div {
  padding: 10px 5px !important;
}

.makeStyles-sidebarMinimize-119 {
  padding-left: 5px !important;
}

.notification,
.logOutBtn {
  button {
    padding: 12px 15px;
  }
}

.logoMobView,
.notification .btnTxt,
.logOutBtn .btnTxt {
  display: none;
}

// toast
.toast {
  background-color: rgba(174, 132, 88, 0.95) !important;
  border: 1px solid rgba(174, 132, 88, 0.95) !important;
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  letter-spacing: 0.5px;
  flex-basis: 0 !important;
}

.paginationWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 20px;
}

.ignoreDistance {
  .MuiFormControlLabel-label {
    vertical-align: -1px;
  }
}

.bookOffered {
  color: #333 !important;
}
