.text-theme {
  color: $primary-color !important;
}

.text-primary {
  color: $success-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.bg-theme {
  background-color: $primary-color !important;
}

.bg-white {
  background-color: white !important;
}

hr {
  border-top-color: $primary-color !important;
  height: 1px !important;
}

a,
.fa-comment,
.input-icon {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center !important;
}

.text-secondary {
  color: #999 !important;
}

.br-1 {
  border-radius: 0.7rem !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.mt-2 {
  margin-top: 1.3rem !important;
}

.w-100 {
  width: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.pgTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $primary-color;
}

.pageTitle {
  color: $primary-color !important;
  text-transform: uppercase;
}

.fit-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.toast {
  color: white !important;
}

// ========= Card ===============

.makeStyles-cardHeader-759 {
  border-bottom: 1px solid #bbb6b6 !important;
}

.card-header {
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid #ccc !important;

  .card-heading {
    margin-top: 0 !important;
  }
}

.card-footer {
  background-color: #f7f7f7 !important;
  border-top: 1px solid #ccc !important;
  justify-content: flex-end !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 7px !important;
}

.card-heading,
.card-author {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-heading {
  margin-bottom: 0 !important;
  font-size: 15px;
  color: #333;
  font-weight: 500;
  margin-top: 7px;
}

.card-author {
  margin-top: 4px !important;
  color: #4f4f4f;
  margin-bottom: 5px;
}

.card-para {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 7px;
  font-size: 12px;
  color: #828282;
  font-weight: 400;
  margin-bottom: 5px;
}

.card-button-container button {
  padding: 0 15px !important;
  margin-bottom: 10px;
  margin-right: 15px;
  height: 28px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  margin: 0;
  margin-right: 8px;
}

.card-button-container button:last-child {
  margin-right: 0px;
}

.card-price-container,
.card-button-container {
  position: relative;
}

.card-price-container .card-img {
  height: 220px;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  width: 100%;
}

.card-price {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 8px;
  border-bottom-right-radius: 10px;
  background: #acd367;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.3);
}

.card-button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 8px 5px;
}

// =========== Modal ==========

.modalBody {
  width: 85%;
  max-width: 800px;
  margin: auto;
}

// ============== Forms Common Classes ================
.MuiFormControl-root {
  width: 100% !important;
}

.ModalFormRow .MuiFormControl-root {
  margin-top: 10px !important;
}

.MuiChip-root {
  margin-bottom: 5px !important;
  margin-right: 5px !important;
}

.MuiButton-label .fab {
  margin-right: 13px !important;
}

.makeStyles-button-3 {
  text-transform: capitalize !important;
}

.makeStyles-info-94 {
  box-shadow: 0 2px 2px 0 #5e81bf !important;
  background-color: #5e81bf !important;
}

.makeStyles-primary-93 {
  box-shadow: 0 2px 2px 0 #ba4640 !important;
  background-color: #ba4640 !important;
}

.makeStyles-rose-98 {
  box-shadow: 0 2px 2px 0 #d97443 !important;
  background-color: #d97443 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  border-bottom: 1px solid #33333346;
  padding-bottom: 10px;
}

// ===========
footer ul li a {
  text-transform: capitalize !important;
}

.makeStyles-card-324,
.makeStyles-card-172 {
  box-shadow: 6px 6px 25px 4px rgba(0, 0, 0, 0.21) !important;
  border-radius: 12px !important;
}
