// ======== Media Styles ===========
@media (max-width: 1200px) {
  .loginForm {
    width: 80%;
  }

  .my-profile
    .my-profile-form
    .ModalFormRow
    .flex-center
    > .MuiFormControl-root {
    width: 100% !important;
  }

  .my-profile .my-profile-form .ModalFormRow .customTag {
    > .MuiFormControl-root,
    .MuiInputLabel-root {
      width: 100% !important;
    }
  }

  .card-button-container {
    position: static !important;
    padding: 0 !important;
    margin-top: 10px;
    margin-bottom: 3px;
  }
}

@media (max-width: 960px) {
  .d-md-none {
    display: none !important;
  }

  .makeStyles-content-3 {
    padding: 0 !important;
  }

  .card-price-container {
    .card-img {
      height: 200px;
    }
  }

  .registerPage {
    .loginForm {
      .margin-top {
        margin-top: 0 !important;
      }
      .MuiFormControl-root {
        margin-bottom: 20px !important;
      }

      .loginButton {
        margin-bottom: 0 !important;
      }
    }

    .makeStyles-grid-1 {
      width: 100% !important;
      margin: 0 !important;
    }

    .makeStyles-grid-2 {
      padding: 0 !important;
    }
  }

  .loginPage .makeStyles-grid-1.h-100 > .makeStyles-grid-2:last-child {
    padding: 0 !important;
  }
}

@media (max-width: 959.98px) {
  .logoMobView {
    position: absolute;
    top: 9px;
    left: 20px;
    display: initial;
    height: 32px;
  }

  .logOutBtn {
    position: absolute;
    top: 500px;
    width: 100%;
    left: 0;

    button {
      padding: 10px 32px;
      width: 100%;
      justify-content: flex-start;
      margin: 0;
    }

    .btnTxt {
      font-size: 14px;
      text-transform: capitalize !important;
      color: #fff;
      font-weight: 300;
      letter-spacing: 0.5px;
      display: initial;
    }

    .fa {
      font-size: 20px !important;
      width: 30px;
      margin-right: 9px;
      text-align: left;
    }
  }

  .welcome-message {
    padding: 25px 25px 5px;
    width: 100%;
    margin-left: 0;
    color: #fff !important;
    letter-spacing: 0.3px;
  }
}

@media (max-width: 767.98px) {
  .MyBookShelfModal {
    .modalBody {
      width: 100% !important;
      padding: 0 15px !important;
    }

    .w-100 {
      max-height: calc(100vh - 160px) !important;
    }
  }

  .h-90 {
    height: 100% !important;
  }

  .customFilterWrap {
    display: block;

    .searchBook {
      margin-bottom: 18px;
    }

    .customWidth {
      width: 50%;
      display: inline-block;
      vertical-align: top;
    }
  }

  .bookTableCard {
    margin-top: 15px !important;
  }

  .clear-all-custom {
    margin-top: -5px;
    font-size: 14px;
  }

  .loginPage {
    height: calc(100vh - 55px);
  }

  .loginForm,
  .registerPage .loginForm {
    width: 90% !important;
  }

  .registerPage {
    > div {
      overflow-y: auto;
    }

    .h-100 {
      height: auto !important;
      overflow-y: auto !important;
    }
  }

  .DayPicker_transitionContainer__vertical {
    height: 630px !important;
  }

  .DayPickerNavigation__verticalDefault {
    height: 40px !important;
  }

  .DayPickerNavigation_svg__vertical {
    height: 30px !important;
    width: 30px !important;
  }

  .DayPickerNavigation_button__default {
    border: 0 !important;
  }

  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 0 !important;
  }
}

@media (max-width: 600px) {
  .ModalFormRow .order-2 {
    order: 2;
  }

  .pgTitleWrap button.bg-theme,
  .formSubmit,
  .card-footer button {
    padding: 8px 20px !important;
  }

  .pgTitleWrap button.addBtn {
    padding: 0 18px !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ModalFormRow .MuiGrid-grid-sm-6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .registerPage .h-90 {
    height: 80vh !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 576px) {
  .MyBookShelfModal {
    max-height: calc(100% - 3.5rem);
    margin: 1.75rem auto;
  }
}

@media (max-width: 575.98px) {
  .my-profile {
    padding: 25px 15px;
  }

  .content .pageTitle {
    font-size: 18px !important;
  }

  .poweredBy {
    text-align: center;
    margin-top: 5px;
  }

  .login-footer-links {
    text-align: center;
  }

  footer {
    padding: 10px 0 !important;
    text-align: center;

    .footer-left,
    .footer-right {
      float: unset !important;
    }

    ul li a {
      padding: 5px 15px;
    }

    .footer-right {
      padding-top: 0;
      padding-bottom: 5px;
    }
  }

  .makeStyles-button-91 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .makeStyles-justIcon-119 {
    margin-top: 0 !important;
  }

  .makeStyles-content-3,
  header + div {
    min-height: calc(100vh - 153px) !important;
  }

  .my-requests-button {
    button {
      width: 140px;
    }

    .ml {
      margin-left: 20px !important;
    }
  }

  .MyBookShelfModal {
    max-height: calc(100% - 9rem);
    margin: 4.5rem auto;

    .w-100 {
      max-height: calc(100vh - 248px) !important;
    }
  }

  .backBtn {
    width: 21px;
    min-width: 21px;
    height: 21px;
    min-height: 21px;
    line-height: 19px;
    font-size: 12px;
  }

  .chat-page {
    .chatBoxModal {
      height: calc(100vh - 229px);

      .chatBoxContainer #chat-panel {
        height: calc(100vh - 345px);
      }
    }
  }

  .paginationWrap {
    flex-direction: column;
    justify-content: center;

    .poweredBy {
      margin-top: 0;
      margin-bottom: 18px;
    }
  }
}
